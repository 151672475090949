.loader {
  border: 4px solid #ffffff; /* Light grey */
  border-top: 4px solid #d65205; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadingContainer{
  position:fixed;
  display:grid;
  place-content:center;
  width: 100%;
  height: 100%;
 
  background:radial-gradient(rgba(255, 157, 9, 0.212),rgba(190, 66, 16, 0.596));
  
  z-index: 999999;
}